import { Link } from 'gatsby';
import React from 'react';

export default function TagBlock(props) {
  const { tags, tagSlugs } = props;
  if (!tagSlugs?.length) return null;
  return (
    <ul className="tags">
      {tagSlugs.map((tag, i) => (
        <li key={tag}>
          <Link to={tag}>
            {tags[i]}
          </Link>
        </li>
      ))}
    </ul>
  );
}
