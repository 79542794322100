import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import React from 'react';

import Layout from '#/components/layout';
import PostMain from '#/components/post-main';

export default function PostTemplate(props) {
  const {
    data,
    location,
  } = props;
  const {
    site: {
      siteMetadata: { title },
    },
    markdownRemark: post,
  } = data;
  const {
    frontmatter: {
      title: postTitle,
      description,
    },
  } = post;
  const isReadMode = location.search.slice(1).split('&').includes('m=read');

  return (
    <Layout hideHeader={isReadMode} showIndicator>
      <Helmet>
        <title>{`${postTitle} - ${title}`}</title>
        <meta name="description" content={description} />
      </Helmet>
      <PostMain data={data} isReadMode={isReadMode} />
    </Layout>
  );
}

export const pageQuery = graphql`
  query PostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      fields {
        tagSlugs
        type
      }
      frontmatter {
        title
        tags
        date
        description
      }
      tableOfContents
    }
  }
`;
