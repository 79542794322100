import { format } from 'date-fns';
import React, { useRef } from 'react';
import TOC from '../toc';
import Disqus from '../disqus';
import TagBlock from '../tag-block';

export default function PostMain(props) {
  const {
    data: {
      markdownRemark: post,
    },
    isReadMode,
  } = props;
  const {
    frontmatter: {
      title: postTitle,
      date,
      tags,
    },
    fields: {
      tagSlugs,
    },
    html,
    tableOfContents,
  } = post;

  const commentsBlock = !isReadMode && <Disqus postNode={post} />;
  const articleRef = useRef();
  return (
    <main className="post-page">
      <section className="post-page-header">
        <h1>{postTitle}</h1>
        <time>{format(new Date(date), 'MMMM d, yyyy')}</time>
      </section>
      <section className="post-page-body">
        <TOC data={tableOfContents} articleRef={articleRef} />
        <article ref={articleRef} dangerouslySetInnerHTML={{ __html: html }} />
      </section>
      <section className="post-page-footer">
        <hr />
        {!isReadMode && <TagBlock tags={tags} tagSlugs={tagSlugs} />}
      </section>
      {commentsBlock}
    </main>
  );
}
