import React, { useRef, useEffect } from 'react';
import onScroll from '#/common/scroller';

export default function TOC(props) {
  const { data } = props;
  const ref = useRef();
  useEffect(() => {
    if (!ref.current) return;
    const list = Array.from(ref.current.querySelectorAll('a'));
    list.forEach(a => {
      a.dataset.id = decodeURIComponent(a.href.split('#')[1] || '');
    });
    const listener = () => {
      const { articleRef } = props;
      if (!articleRef.current || !ref.current) return;
      const headings = Array.from(articleRef.current.children, el => {
        return el.id && {
          id: el.id,
          offset: el.getBoundingClientRect().top - 60,
        };
      })
      .filter(Boolean);
      const { id } = headings.find((_, i) => {
        const next = headings[i + 1];
        return next && next.offset > 0;
      }) || {};
      list.forEach(el => {
        el.className = el.dataset.id === id ? 'active' : '';
      });
    };
    return onScroll(listener);
  });

  // Gatsby bug: https://github.com/gatsbyjs/gatsby/issues/8982
  const html = (data || '').replace(/&#x3C;/gi, '<');
  return (
    <div className="toc lg:tw-order-3">
      {html ? (
        <>
          <h2 className="lg:tw-hidden">目录</h2>
          <div className="toc-content" ref={ref} dangerouslySetInnerHTML={{ __html: html }} />
        </>
      ) : null}
    </div>
  );
}
